window.comms_requests = [];

(function(){

    let open = window.XMLHttpRequest.prototype.open;
    let send = window.XMLHttpRequest.prototype.send;
    let xhr_event = new Event('commsAPI_XHR');

    function openReplacement(method, url, async, user, password) {
        this._url = url;
        this._args = arguments;
        return open.apply(this, arguments);
    }

    function sendReplacement(data) {
        this._data = data;
        if(this.onreadystatechange) {
            this._onreadystatechange = this.onreadystatechange;
        }
        this.onreadystatechange = onReadyStateChangeReplacement;
        return send.apply(this, arguments);
    }

    function getTime() {
        let d = new Date();
        return `${d.getHours()}:${d.getMinutes()} ${d.getSeconds()}.${d.getMilliseconds()}`
    }

    function onReadyStateChangeReplacement() {
        if (this.readyState === 4) {
            if (this._url.indexOf('comms') > -1 && this._url.indexOf('comms/debug') === -1) {
                window.comms_requests.push(
                    {
                        time: getTime(),
                        url: this._url,
                        args: this._args,
                        pdata: this._data,
                        headers: this.getResponseHeader('X_DEBUGBAR_REQUEST_HEADERS'),
                        filtered_contents: JSON.parse(this.response)
                    }
                )
                window.dispatchEvent(xhr_event);
            }
        }
        if(this._onreadystatechange) {
            return this._onreadystatechange.apply(this, arguments);
        }
    }

    window.XMLHttpRequest.prototype.open = openReplacement;
    window.XMLHttpRequest.prototype.send = sendReplacement;

})();